import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col, ErrorBoundary, Button, IconButton, H4, Theme, SizerWrapper, TabBar, Tab, AddIcon, UploadIcon, AlertNotification, colors, InfoIcon, Typography } from '@commonsku/styles';
import { oauth, _createEventHandler, getIdentityUtils } from '../utils';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import createPopup from '../popup-factory';
import { getPopups } from '../selectors';
import {
  createLoadAccountStatuses,
  createLoadIndustries,
  createLoadTags,
  createLoadUserReps,
  createLoadDepartmentList,
} from '../actions';
import { createNewFeaturePopup } from '../actions/popup';
import { createLoadDivision, createLoadSupplier } from '../actions/supplier';
import { createGetClient } from '../actions/client';
import {
  createLoadTaxesList,
  createLoadCompanyTermsList,
  createLoadCompanyClientList,
  createClearClientState,
  createLoadCompanyUserList,
  createLoadTeamList,
} from '../actions/company_search';
import { useStateWithCallback } from '../hooks';
import { Pagination, LabeledSelect, createGlobalStyle } from '../components/helpers';
import {
  ClientsTable,
  ConnectedCreateClientPopup as CreateClientPopup,
  ClientImportPopup,
  ClientImportPreviewPopup,
  ConnectedClientSidePanel as ClientSidePanel,
} from '../components/company-search';
import DebouncedSearchBox from '../components/DebouncedSearchBox';
import { getOptions, getAllTagOptions, getDeptOptions } from '../components/company-search/selectors';

import '../../scss/css/clients.css';
import PortalPopup from '../components/company-search/popups/PortalPopup';
import ClientPortals from '../components/client-portal-admin/ClientPortals';
import { ToastContainer } from 'react-toastify';
import StandaloneReportContainer from '../components/report/StandaloneContainer';
import { ContactImportHubspotJobImport } from '../components/ContactImportHubspotJobImport';

const GlobalStyle = createGlobalStyle();

function SearchFilters({ filters, onUpdate, filterOptions, ...props }) {
  return (
    <Row style={{ placeContent: 'flex-start' }}>
      <Col padded xl={2} sm={4} xs={6}>
        <DebouncedSearchBox
          label="Name"
          name="filter_name"
          handleSearch={(val) => onUpdate({ company_name: val, 'start-index': 0 })}
          debounceInterval={600} />
      </Col>

      <Col padded xl={2} sm={4} xs={6}>
        <LabeledSelect
          label="Industry"
          name="filter_industry"
          value={filters.industry_id}
          options={filterOptions.industries_all}
          onChange={_createEventHandler(onUpdate, 'industry_id', { type: 'select', additionalPayload: { 'start-index': 0 } })}
          onFocus={(e) => e.target.select()}
          menuShouldScrollIntoView={false}
        />
      </Col>

      <Col padded xl={2} sm={4} xs={6}>
        <LabeledSelect
          label="Rep"
          name="filter_rep"
          value={filters.sales_rep_id}
          options={filterOptions.reps_all}
          onChange={_createEventHandler(onUpdate, 'sales_rep_id', { type: 'select', additionalPayload: { 'start-index': 0 } })}
          onFocus={(e) => e.target.select()}
          menuShouldScrollIntoView={false}
        />
      </Col>

      <Col padded xl={2} sm={4} xs={6}>
        <LabeledSelect
          label="Status"
          name="filter_status"
          value={filters.account_status_id}
          options={filterOptions.statuses_all}
          onChange={_createEventHandler(onUpdate, 'account_status_id', { type: 'select', additionalPayload: { 'start-index': 0 } })}
          onFocus={(e) => e.target.select()}
          menuShouldScrollIntoView={false}
        />
      </Col>

      <Col padded xl={4} sm={8} xs>
        <LabeledSelect
          label="Tags"
          name="filter_tags"
          isMulti
          value={filters.tags}
          options={filterOptions.all_tags_labels}
          onChange={_createEventHandler(onUpdate, 'tags', { type: 'select', isMulti: true, additionalPayload: { 'start-index': 0 } })}
          onFocus={(e) => e.target.select()}
          menuShouldScrollIntoView={false}
        />
      </Col>
    </Row>
  );
}

/**
 * On component did mount
 * @param {Object} data
 */
function onInit({ user_id, onCreateNewFeaturePopup, callback }) {
  // remove resku from body and html tags
  document.getElementsByTagName('body')[0].className = '';
  document.getElementsByTagName('html')[0].className = '';

  oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
    if (json.show_popup == 1) {
      onCreateNewFeaturePopup();
    }
  });

  if (callback && typeof callback === 'function') {
    callback();
  }
}

function CompanySearchApp({
  user_id,
  identity,
  clients,
  companies,
  filterOptions,
  shops,
  chatBots,
  company_search,

  clearClient,
  onCreateNewFeaturePopup,
  popups,
  loadAccountStatuses,
  loadIndustries,
  loadTags,
  loadUserReps,
  loadTaxes,
  loadTerms,
  loadCompanyUsers,
  loadTeams,
  getClient,
  ...props
}) {
  const initialFilters = {
    company_type: 'CLIENT',
    'start-index': 0,
    'max-results': 50,
    'order-by': 'latest_use',
    'order-dir': 'desc',
    search: true,
    with_sales_report: true,
    with_last_activity: '',
    company_name: '', tags: [], industry_id: '', sales_rep_id: '', account_status_id: '',
  };
  const [search_filters, setSearchFilters] = useStateWithCallback(initialFilters, (state) => {
    props.loadCompanyClients(state);
  });
  const [showPopup, setShowPopup] = useState(false);
  const [activePortal, setActivePortal] = useState(null);
  const [portalTabIndex, setPortalTabIndex] = useState(0);
  const [sidePanel, setSidePanel] = useState({ tab_index: 0, client_id: null, editDetails: false });
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [previewReady, setPreviewReady] = useState(false);
  const [preview, setPreview] = useState(null);
  const [previewImportId, setPreviewImportId] = useState(null);

  const showPortalPopup = useCallback((activePortal, tabIndex) => { setActivePortal(activePortal); setShowPopup('portal'); setPortalTabIndex(tabIndex ?? 0); }, []);
  const showAnotherPortalPopup = useCallback((anotherPortal) => {
    setActivePortal(anotherPortal);
    window.setTimeout(() => setShowPopup('portal'), 10); // Have to use this hack. Otherwise, it will cause react's render issue
  }, []);

  useEffect(function () {
    onInit({
      user_id, onCreateNewFeaturePopup, callback: () => {
        loadAccountStatuses();
        loadIndustries();
        loadTags();
        loadUserReps(identity.company_id, identity.company_type);
        loadTaxes({ tenant_id: identity.company_id });
        loadTerms();
        loadCompanyUsers(identity.company_id, identity.company_type, {
          'max-results': 4294967296,
          report: true,
          exclude_default_company_users: true,
        });
        loadTeams({ team_type: 'GROUP' });
        props.loadDepartments();
        setSearchFilters(initialFilters);
      }
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const activeTab =  urlParams.get("tab") === 'hubspot' ? 2 : 0;
    setSelectedTabIndex(activeTab);
  }, []);

  const { isAdmin, hasCapabilities, isAssuming, hasUserFlags } = getIdentityUtils(identity);
  const hubspotImportEnabled = useSelector(state => state.hubspot_import_enabled);

  function hasOrderAccess() {
    return isAdmin()
      || hasCapabilities([
        'CREATE-OPPORTUNITY', 'CREATE-PRESENTATION', 'CREATE-ESTIMATE', 'CREATE-SALES-ORDER',
        'MODIFY-ORDER',
      ], true);
    // || hasUserFlags(['CREATE-PROJECT']);
  }

  const bulkImportAccess = isAssuming();

  function accountAccess() {
    return isAdmin()
      || hasCapabilities(["MODIFY-DOWNSTREAM-ACCOUNT", "CREATE-DOWNSTREAM-ACCOUNT", 'MODIFY-CLIENT'], true);
  }
  function canViewOwnTeamsReport(client_rep_user_id) {
    return !hasCapabilities(['LIMITED-ACCESS', 'RESTRICTED-ACCESS'], true)
      && hasCapabilities(['VIEW-REPORT-OWN-TEAMS'])
      && (identity.user_id === client_rep_user_id || checkInCompanyUsers(client_rep_user_id));
  }

  function hasLimitedAccess(client_rep_user_id) {
    return hasCapabilities(['LIMITED-ACCESS', 'RESTRICTED-ACCESS'], true)
      && identity.user_id === client_rep_user_id;
  }

  function hasAccess(client_rep_user_id) {
    return isAdmin() || hasLimitedAccess(client_rep_user_id)
      || (!hasCapabilities(['LIMITED-ACCESS', 'RESTRICTED-ACCESS'], true) &&
        hasCapabilities(['VIEW-REPORT-ALL-USERS']))
      || canViewOwnTeamsReport(client_rep_user_id)
      || identity.user_id === client_rep_user_id;
  }

  function checkInCompanyUsers(user_id) {
    if (props.company_users && props.company_users.length) {
      for (let i = 0; i < props.company_users.length; i++) {
        const user = props.company_users[i];
        if (user.user_id === user_id) {
          return true;
        }
      }
    }
    return false;
  }

  function renderPopup() {
    if (previewReady) {
      return <ClientImportPreviewPopup
        onClosePopup={() => {
          setPreviewReady(false);
        }}
        preview={preview}
        previewImportId={previewImportId}
      />;
    }

    if (showPopup === 'add_client') {
      return <CreateClientPopup
        onClosePopup={() => {
          setShowPopup(false);
        }}
        industry_options={filterOptions.industries}
        sales_rep_id={identity.user_id}
      />;
    } else if (showPopup === 'import_clients') {
      return <ClientImportPopup
        onClosePopup={() => {
          setShowPopup(false);
        }}
        sales_rep_options={filterOptions.reps}
        department_options={filterOptions.departments}
        industry_options={filterOptions.industries}
        terms_options={filterOptions.terms}
        tax_options={filterOptions.taxes}
        setPreview={(preview, biid) => {
          setPreview(preview);
          setPreviewImportId(biid);
          setPreviewReady(true);
        }}
      />;
    } else if (showPopup === 'portal') {
      return <PortalPopup
        onClosePopup={() => {
          setShowPopup(false);
        }}
        industry_options={filterOptions.industries}
        tag_options={filterOptions.all_tags_labels}
        clients={companies}
        shops={shops}
        portalId={activePortal?.client_portal_id}
        showAnotherPortalPopup={showAnotherPortalPopup}
        chatBots={chatBots}
        initTabIndex={portalTabIndex}
        hasAccess={hasAccess}
      />;
    }

    return null;
  }

  const onUpdate = (data) => setSearchFilters({ ...search_filters, ...data });
  const updateSidePanel = (data = {}) => { setSidePanel({ ...sidePanel, ...data }); };
  const closeSidePanel = (data = {}) => {
    setSidePanel({ ...sidePanel, client_id: null, editDetails: false, action: '', autofocusField: '', access: false, ...data });
    clearClient();
  };

  const onClickRow = (client_id, data = {}) => {
    closeSidePanel();
    // closeSidePanel({client_id, ...data });
    Promise.resolve().then(() => {
      if (client_id) {
        updateSidePanel({ client_id, ...data });
        getClient(client_id);
      }
    });
  };

  const common_props = {
    hasAccess,
    isAdmin: isAdmin(),
    hasCapabilities,
    canViewOwnTeamsReport,
    identity,
    loading: props.loading
  };

  let tabs = [
    {
      label: 'Client List',
      content: (<SizerWrapper xsStyle="padding-left: 0;" mdStyle="padding-left: 10px;" style={{ paddingRight: 10 }}>
        <SearchFilters {...common_props}
          onUpdate={onUpdate}
          filterOptions={{
            ...filterOptions,
            reps_all: hasCapabilities(['LIMITED-ACCESS', 'RESTRICTED-ACCESS'], true)
              ? filterOptions.reps_all.filter(v => v.value === identity.user_id || v.label === 'All')
              : filterOptions.reps_all
          }}
          filters={search_filters}
        />
        <Row>
          <Col xs padded>
            <ClientsTable {...common_props}
              onUpdate={onUpdate}
              onClickRow={(client_id, data) => onClickRow(client_id, data)}
              companies={companies}
              search_filters={search_filters}
              selected_client={sidePanel.client_id}
              onClosePanel={() => closeSidePanel({ tab_index: 0 })}
            />
          </Col>
        </Row>
        <Pagination
          data={companies}
          max_results={search_filters['max-results']}
          start_index={search_filters['start-index']}
          onUpdate={onUpdate} />
      </SizerWrapper>)
    },
  ];

  if (hasCapabilities(['HAS-CLIENT-PORTALS'])) {
    tabs.push({
      label: 'Portals',
      content: (
        <div style={{ marginLeft: '1rem' }}>
          <ClientPortals
            showPortalPopup={showPortalPopup}
            industry_options={filterOptions.industries}
            tag_options={filterOptions.all_tags_labels}
            clients={companies} />
        </div>
      )
    });
  }

  const hubspotImportReport = useMemo(() => <StandaloneReportContainer reportType='contact-import-hubspot-contact' loadInitially={true} />, []);
  if (hubspotImportEnabled) {
    tabs.push({
      label: 'HubSpot Sync',
      content: (
        <>
          <div style={{ marginLeft: '1rem', marginRight: '1rem', paddingBottom: '3rem' }}>
            <Row style={{ backgroundColor: colors.navy.lightest, borderRadius: '10px', padding: '10px 20px' }}>
              <Col sm={6} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                <InfoIcon color={colors.texttitle} />
                <Typography.Text>The sync is configured in the <a href="/admin_settings_new.php" target='_blank'>integration</a> popup in the Admin area</Typography.Text>
              </Col>
              <Col sm={6} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', justifyContent: 'flex-end' }}>
                <ContactImportHubspotJobImport />
              </Col>
            </Row>
            <Row style={{ padding: '10px 20px' }}>
              <Col sm={12}><Typography.Text>We couldn't match the following contacts with an existing client</Typography.Text></Col>
            </Row>
            {hubspotImportReport}
          </div>
        </>
      )
    });
  }

  return (
    <Theme>
      <GlobalStyle />
      <ErrorBoundary>
        <div className="resku">
          <Header>
            <div className="large-12 columns">
              <Row style={{ marginBottom: 10 }}>
                <Col xs={6} xsStyle="margin-left: 0px;" mdStyle="margin-left: -18px;">
                  <H4 style={{ fontWeight: 500, marginBottom: 0 }}>Clients</H4>
                </Col>
              </Row>
            </div>
          </Header>
        </div>
        <div>
          <div className="csku-styles">
            <ClientSidePanel {...common_props}
              updatePanelSettings={(data) => updateSidePanel(data)}
              client_id={sidePanel.client_id}
              settings={sidePanel}
              filterOptions={filterOptions}
              accountAccess={accountAccess}
              hasOrderAccess={hasOrderAccess}
              onClosePanel={(data = {}) => closeSidePanel(data)}
              hasCapabilities={hasCapabilities}
            />
            {renderPopup()}
          </div>

          <div className="resku"><MainSection /></div>

          <div className="main-content csku-styles" style={{ marginTop: '0rem' }}>
            <div className="new-action-button">
              {selectedTabIndex === 0 && bulkImportAccess ? <IconButton
                Icon={<UploadIcon />}
                style={{ marginRight: '0.2rem' }}
                onClick={() => setShowPopup('import_clients')}
              >
                Import Clients
              </IconButton>
                : ''
              } {selectedTabIndex === 0 && accountAccess() ? <IconButton
                Icon={<AddIcon />}
                onClick={() => setShowPopup('add_client')}
              >
                New Client
              </IconButton>
                : ''
              }
              {selectedTabIndex === 1 && <Button onClick={() => showPortalPopup(null)}>New Portal</Button>}
            </div>
            <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
              {tabs.map((tab, index) => <Tab
                key={'tabs_' + index} size={tabs.length}
                selected={index === selectedTabIndex}
                onClick={() => {
                  setSelectedTabIndex(index);
                  let callback = tabs[index].onClick;
                  if (callback) { callback(); }
                }}>
                {tab.label}
              </Tab>)}
            </TabBar>
            {tabs[selectedTabIndex] ? tabs[selectedTabIndex].content : null}
          </div>
          <div className="resku">
            {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
          </div>
        </div>
        <div className="resku">
          <Overlay popups={popups} />
        </div>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
        />
      </ErrorBoundary>
    </Theme>
  );
}

const mapStateToProps = (state, ownProps) => {
  const company_search = state.entities.company_search || {};
  return {
    identity: state.identity,
    popups: getPopups(state),
    user_id: state.identity.user_id,
    filterOptions: {
      all_tags_labels: getAllTagOptions(state),
      statuses: getOptions(state.dropdowns.account_statuses, 'account_status_id', 'account_status_name'),
      statuses_all: getOptions(state.dropdowns.account_statuses, 'account_status_id', 'account_status_name', false, [{ label: 'All', value: '' }, { label: 'None', value: 'null' }]),
      industries: getOptions(state.dropdowns.industries, 'industry_id', 'industry_name'),
      industries_all: getOptions(state.dropdowns.industries, 'industry_id', 'industry_name', false, { label: 'All', value: '' }),
      reps: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name)),
      reps_all: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name), false, [{ label: 'All', value: '' }, { label: 'None', value: 'none' }]),
      terms: getOptions(state.dropdowns.terms, 'terms_id', 'terms_name'),
      terms_all: getOptions(state.dropdowns.terms, 'terms_id', 'terms_name', false, { label: 'All', value: '' }),
      taxes: getOptions(state.dropdowns.taxes, 'tax_id', 'label'),
      taxes_all: getOptions(state.dropdowns.taxes, 'tax_id', 'label', false, { label: 'All', value: '' }),
      departments: getDeptOptions(state),
    },
    company_search: company_search,
    company_users: Object.values(company_search.company_users || {}),
    teams: Object.values(company_search.teams || {}),
    loading: state.display.loading,
    companies: Object.values(company_search.companies || {}),
    clients: Object.values(state.entities.clients || {}),
    shops: state.entities.shops || [],
    chatBots: state.entities.chat_bots || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    },
    loadDivisions: supplier_type => {
      if ('collection' === supplier_type) {
        dispatch(createLoadSupplier(supplier_type));
      } else {
        dispatch(createLoadDivision('commonsku' === supplier_type ? 'supplier-direct' : supplier_type));
      }
    },
    loadAccountStatuses: (company_type = 'CLIENT') => {
      dispatch(createLoadAccountStatuses(company_type));
    },
    loadIndustries: () => {
      dispatch(createLoadIndustries());
    },
    loadTags: (resource_type = 'CLIENT') => {
      dispatch(createLoadTags(resource_type));
    },
    loadUserReps: (parent_id, parent_type = 'TENANT', exclude_default_company_users = true) => {
      dispatch(createLoadUserReps(parent_id, parent_type, exclude_default_company_users));
    },
    loadTaxes: (options = {}) => {
      dispatch(createLoadTaxesList(options));
    },
    loadTerms: (options = {}) => {
      dispatch(createLoadCompanyTermsList(options));
    },
    loadCompanyClients: (filters) => {
      dispatch(createLoadCompanyClientList(filters));
    },
    getClient: (client_id, params = {}) => {
      dispatch(createGetClient(client_id, { with_last_activity: true, with_sales_report: true }));
    },
    clearClient: () => dispatch(createClearClientState()),
    loadCompanyUsers: (parent_id, parent_type = 'TENANT', options = {}) => dispatch(createLoadCompanyUserList(parent_id, parent_type, options)),
    loadTeams: (options = {}) => dispatch(createLoadTeamList(options)),
    loadDepartments: (parent_type = 'CLIENT') => dispatch(createLoadDepartmentList(parent_type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySearchApp);