import React, { useMemo, useState } from "react";
import styled from 'styled-components';
import { Csku, colors } from "@commonsku/styles";

export interface BulkAction<T extends Record<string, unknown> = Record<string, unknown>> {
  (props: {
    selected: T[],
    setExecuting: (executing: boolean) => void,
    disabled?: boolean,
  }): React.ReactNode;
}

export interface BulkActionBarProps<T extends Record<string, unknown> = Record<string, unknown>> {
  selected: T[];
  prepareLabel: (selected: T[]) => React.ReactNode;
  actions: BulkAction<T>[];
  style?: React.CSSProperties;
}

const BulkActionContainer = styled(Csku) <{ $active: boolean; }>`
&&& {
  position: fixed;
  bottom: 0;
  height: 69px;
  width: 100%;
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.$active ? colors.neutrals['100'] : colors.neutrals['80']};
  color: ${colors.white};
  margin: 0;
}`;

const ActionButtonContainer = styled(Csku)`
&&& {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}`;

function BulkActionBar({ selected, prepareLabel, actions, style }: BulkActionBarProps) {
  const [executing, setExecuting] = useState(false);
  const label = prepareLabel(selected);

  const renderedActions = actions.map(
    (Action, index) => (
      <Action
        key={index}
        selected={selected}
        setExecuting={setExecuting}
        disabled={executing || selected.length === 0}
      />
    )
  ).filter(
    action => !!action
  );

  if (renderedActions.length === 0) {
    return null;
  }

  return (
    <BulkActionContainer $active={selected.length > 0} style={style}>
      {label}
      <ActionButtonContainer>{renderedActions}</ActionButtonContainer>
    </BulkActionContainer>
  );
}

export default BulkActionBar;
