import { useEffect, useCallback } from 'react';
import { selectors, getAll, addItem } from '../redux/contactImportHubspotJobs';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import useInterval from './useInterval';

export default function useContactImportHubspotJobs(refresh) {
    const dispatch = useDispatch();
    const reduxState = useSelector((state: any) => state.contact_import_hubspot_jobs);
    const items = selectors.selectAll(reduxState);
    const loading = reduxState.loading;

    const getJobs = useCallback(() => dispatch(getAll()), [dispatch]);

    useInterval(
        () => getJobs(),
        refresh ? 10 * 1000 : null
    );

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    const newJob = useCallback(async () => {
        await dispatch(addItem());
        toast.success("Saved successfully");
    }, [dispatch]);

    return [
        items,
        loading,
        newJob,
    ];
};
