import React, { useEffect, useState } from 'react';
import { Button, CompletedCheckmarkIcon, SkubotSpinner, Typography } from '@commonsku/styles';
import useContactImportHubspotJobs from '../hooks/useContactImportHubspotJobs';
import { formatFriendlyDateTime, toTimestamp } from '../utils';

export const ContactImportHubspotJobImport = () => {
    const [jobs, , addJob] = useContactImportHubspotJobs(true);
    const [isImporting, setIsImporting] = useState(false);
    const [newJobTime, setNewJobTime] = useState(null);
    useEffect(() => {
        const jobInProgress = jobs.length > 0 && !jobs[0].date_completed;

        if (!newJobTime) {
            setIsImporting(jobInProgress);
        }

        if (newJobTime && jobs[0] && jobs[0].date_completed && newJobTime.valueOf() < new Date(jobs[0].date_completed).valueOf()) {
            window.location.href = '/clients.php?tab=hubspot';
        }
    }, [jobs, newJobTime]);

    const completedDate = isImporting || jobs.length <= 0 ? '' : formatFriendlyDateTime(toTimestamp(jobs[0]?.date_completed));
    const messageContent = isImporting ?
        <Typography.Text>Importing Contacts</Typography.Text>
        : (completedDate === '' ? null : <><CompletedCheckmarkIcon size="small" /> <Typography.Text>Last imported at {completedDate}</Typography.Text></>);
    return <>
        {messageContent}
        <Button
            onClick={() => { setIsImporting(true); addJob(); setNewJobTime(new Date()); }}
            size='small'
            disabled={isImporting}
        >
            {isImporting ? <SkubotSpinner size="button" skubot={false} /> : 'Import Now'}
        </Button>
    </>;
};
